import type { LinksFunction, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
"@remix-run/react";

import fontsStylesheet from "~/styles/fonts.css";
import stylesheet from "~/styles/tailwind.css";
import favicon from "./favicon.svg";
import { withSentry } from "@sentry/remix";

export const links: LinksFunction = () => [
{
  rel: "preconnect",
  href: "https://fonts.googleapis.com"
},
{
  rel: "preconnect",
  href: "https://fonts.gstatic.com",
  crossOrigin: "anonymous"
},
{ rel: "stylesheet", href: fontsStylesheet },
{ rel: "stylesheet", href: stylesheet },
{ rel: "icon", href: favicon, type: "image/svg+xml" }];


export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Buy Michigan Now Festival Vendor Application",
  description: "Apply to be a vendor at the Buy Michigan Now Festival",
  viewport: "width=device-width,initial-scale=1"
});

export async function loader() {
  return json({
    ENV: { SENTRY_DSN: process.env.SENTRY_DSN }
  });
}
declare global {
  interface Window {
    ENV: {
      SENTRY_DSN?: string;
    };
  }
}

function App() {
  const { ENV } = useLoaderData<typeof loader>();

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }} />

        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export default withSentry(App);